import { Collapse, Flex, Text } from '@chakra-ui/react';
import { PropsWithChildren, useState } from 'react';

import MinusIcon from '@/icons/MinusIcon';
import PlusIcon from '@/icons/PlusIcon';

const MessagingFaqRow: React.FC<PropsWithChildren<{ question: string }>> = ({ children, question }) => {
  const [{ isOpen }, setState] = useState({ isOpen: false });
  return (
    <Flex
      flexDirection="column"
      borderRadius="8px"
      boxShadow="0 6px 10px 0 rgba(0, 0, 0, 0.03)"
      border="1px solid"
      borderColor="white.3"
      backgroundColor="white.0"
      width="100%"
      textAlign="left"
      mx="auto"
      marginBottom="16px"
      _last={{ marginBottom: '0px' }}
    >
      {isOpen ? (
        <Flex
          onClick={() => setState({ isOpen: !isOpen })}
          justifyContent="space-between"
          alignItems="center"
          cursor="pointer"
          p="35px 32px"
        >
          <Text mr="auto" fontSize={{ base: '18px', md: '20px' }} lineHeight="24px" color="purple.0">
            {question}
          </Text>
          <MinusIcon width="14px" height="14px" color="purple.0" flexShrink={0} ml="15px" />
        </Flex>
      ) : (
        <Flex
          onClick={() => setState({ isOpen: true })}
          justifyContent="space-between"
          alignItems="center"
          cursor="pointer"
          p="35px 32px"
        >
          <Text mr="auto" fontSize={{ base: '18px', md: '20px' }} lineHeight="24px" color="black.0">
            {question}
          </Text>
          <PlusIcon name="plus" width="14px" height="14px" color="black.0" flexShrink={0} ml="15px" />
        </Flex>
      )}
      <Collapse in={isOpen} animateOpacity={false}>
        <Flex flexDirection="column" overflow="hidden" opacity={1} transition="all 200ms ease-in-out">
          <Text color="black.1" p="0 32px 32px 32px">
            {children}
          </Text>
        </Flex>
      </Collapse>
    </Flex>
  );
};

export default MessagingFaqRow;
