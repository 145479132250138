import { Icon, IconProps } from '@chakra-ui/react';

const PlusIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <path
        fill="currentColor"
        stroke="none"
        d={`M2.5 6.75h4.25v-4.25c0 0 0-1.25 1.25-1.25 0 0 1.25 0 1.25 1.25v4.25h4.25c0 0 1.25 0 1.25 1.25 0 0 0
        1.25-1.25 1.25h-4.25v4.25c0 0 0 1.25-1.25 1.25 0 0-1.25 0-1.25-1.25v-4.25h-4.25c0 0-1.25 0-1.25-1.25 0 0
        0-1.25 1.25-1.25z`}
      />
    </Icon>
  );
};

export default PlusIcon;
