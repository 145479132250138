import { Icon, IconProps } from '@chakra-ui/react';

const PublishIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 17 15">
      <path
        stroke="none"
        width="100%"
        fillRule="nonzero"
        d={`M10.6523438,5.02734375 C10.9828123,4.70039105 11.5171877,4.70039105 11.8160158,5.02734375 L14.6285158,7.83984375 C14.9871089,8.17031229 14.9871089,8.70468771 14.6285158,9.00351584 C14.3296877,9.36210895 13.7953123,9.36210895 13.4648438,9.00351584 L12.09375,7.66054666 L12.09375,12.375 C12.09375,12.8425777 11.7175777,13.21875 11.25,13.21875 C10.7824223,13.21875 10.40625,12.8425777 10.40625,12.375 L10.40625,7.66054666 L9.00351584,9.00351584 C8.70468771,9.36210895 8.17031229,9.36210895 7.83984375,9.00351584 C7.51289052,8.70468771 7.51289052,8.17031229 7.83984375,7.83984375 L10.6523438,5.02734375 Z M5.0625,15.75 C2.26652348,15.75 0,13.4824219 0,10.6875 C0,8.4832027 1.40871119,6.57773459 3.37500054,5.91328114 L3.37500054,5.90625 C3.37500054,2.64375043 6.01875043,0 9.28125054,0 C11.0707034,0 12.6703128,0.794531465 13.7566407,2.04996085 C14.2804697,1.81687474 14.857032,1.6875 15.4687505,1.6875 C17.7996095,1.6875 19.6875005,3.57539105 19.6875005,5.90625 C19.6875005,6.13828146 19.6699224,6.36328125 19.6347662,6.58476573 C21.3222662,7.32304698 22.5,9.01054645 22.5,10.96875 C22.5,13.5140623 20.5101576,15.5953127 18,15.7429683 L18,15.75 L5.06250054,15.75 L5.0625,15.75 Z M9.28125054,1.6875 C6.95039105,1.6875 5.06250054,3.57539105 5.06250054,5.90625 L5.06250054,7.10507834 L3.90585959,7.50585938 C2.62406248,7.9699223 1.6875,9.22148395 1.6875,10.6875 C1.6875,12.5507812 3.19851601,14.0625 5.06250054,14.0625 L17.810157,14.0625 L17.9015635,14.0554683 C19.5257826,13.9640629 20.8125005,12.6140621 20.8125005,10.96875 C20.8125005,9.703125 20.0531264,8.60976541 18.956251,8.1316402 L17.7574226,7.60781229 L17.9683601,6.31757802 C17.9894541,6.18398416 18.0000005,6.046875 18.0000005,5.90625 C18.0000005,4.50703168 16.8679689,3.375 15.4687505,3.375 C15.0996099,3.375 14.7515633,3.45234418 14.4421876,3.59296918 L13.2960947,4.1027348 L12.4769534,3.15351605 C11.7000001,2.2528131 10.557422,1.6875 9.24961013,1.6875 L9.28125054,1.6875 Z`}
      />
    </Icon>
  );
};

export default PublishIcon;
