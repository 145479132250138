import { Box, BoxProps, Link, Text } from '@chakra-ui/react';

import RouteLink from '@/components/RouteLink';

const LinkText: React.FC<BoxProps & { href?: string; params?: object; text: string; type: string }> = ({
  href,
  params,
  text,
  type,
  ...props
}) => {
  return (
    <Box {...props}>
      {type === 'routeLink' ? (
        <Text
          as={RouteLink}
          borderRadius="25px"
          color="purple.6"
          fontSize="14px"
          fontWeight={400}
          lineHeight="20px"
          params={params}
          to="packageList"
          variant="blackOutline"
          w={{ base: '100%', md: 'auto ' }}
        >
          {text}
        </Text>
      ) : (
        <Link
          borderRadius="25px"
          color="purple.6"
          fontSize="14px"
          fontWeight={400}
          href={href}
          lineHeight="20px"
          variant="blackOutline"
          w={{ base: '100%', md: 'auto ' }}
        >
          {text}
        </Link>
      )}
    </Box>
  );
};

export default LinkText;
