import { Icon, IconProps, Image, ImageProps, Stack } from '@chakra-ui/react';

import PlaceholderIcon from '@/icons/PlaceholderIcon';

type FamilyIconProps = IconProps &
  ImageProps & {
    src?: string;
    alt?: string | undefined;
    isPlaceholder?: boolean;
    direction?: 'row' | 'column';
  };

const FamilyIcon: React.FC<FamilyIconProps> = ({ src, alt, isPlaceholder, direction = 'row', ...props }) => {
  // Enables the three-stack of icons to have spacing relative to icon size
  let spacing;
  if (props.height && typeof props.height === 'string') {
    spacing = parseInt(props.height, 10) * 0.75;
  }

  // If spacing calculation fails, return single icon
  if (!spacing) {
    return isPlaceholder ? (
      <PlaceholderIcon name="placeholder" role="PackageIcon" {...props} zIndex="2" />
    ) : (
      <Image src={src} alt={alt} role="PackageIcon" {...props} zIndex="2" />
    );
  }

  if (isPlaceholder) {
    return (
      <Stack direction={[direction]} spacing={`-${spacing}px`} role="PackageIconGrouping">
        <PlaceholderIcon name="placeholder" role="PackageIcon" {...props} zIndex="2" />
        <PlaceholderIcon role="PackageSubIcon" {...props} zIndex="1" />
        <PlaceholderIcon role="PackageSubIcon" {...props} zIndex="0" />
      </Stack>
    );
  }

  return (
    <Stack direction={[direction]} spacing={`-${spacing}px`} role="PackageIconGrouping">
      <Image src={src} alt={alt} role="PackageIcon" {...props} zIndex="2" />
      <EmptyIcon role="PackageSubIcon" {...props} zIndex="1" />
      <EmptyIcon role="PackageSubIcon" {...props} zIndex="0" />
    </Stack>
  );
};

const EmptyIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 65 65">
      <g fill="none" fillRule="evenodd" stroke="#DCDCDE">
        <rect width="64" height="64" x=".5" y=".5" fill="#FFFFF" rx="15" />
      </g>
    </Icon>
  );
};

export default FamilyIcon;
