import { Button, Center, Flex, Link, Text, Wrap } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import RouteLink from '@/components/RouteLink';
import { RegistryPackageType } from '@/graphql/upbound-graphql';
import PublishIcon from '@/icons/PublishIcon';
import StarOutlineIcon from '@/icons/StarOutlineIcon';
import { accountsRegisterUrl, UPBOUND_DOCS_PUBLISH_URL } from '@/utils/constants/urls';

import VTextCarousel from './TextCarousel';

const TitleButtons: React.FC<{ isSignedIn: boolean }> = ({ isSignedIn }) => {
  const router = useRouter();
  return (
    <Flex width="100%" justifyContent={{ base: 'center', lg: 'left' }}>
      {!isSignedIn ? (
        <Flex direction={{ base: 'column', sm: 'column', lg: 'row' }}>
          <Button
            as={Link}
            borderColor="white.12"
            borderRadius="28px"
            fontSize={{ base: '16px', lg: '25px' }}
            fontFamily="Avenir"
            fontWeight={500}
            h={{ base: '40px', lg: '55px' }}
            href={accountsRegisterUrl()}
            isExternal={true}
            lineHeight="20px"
            p={{ base: '15px 23px 15px', md: '20px 23px' }}
            variant="blackOutline"
            w={{ base: '238px', lg: '364px' }}
            _hover={{ background: '#F8F8F8', textDecoration: 'none' }}
          >
            Create an Upbound Account
          </Button>
          <Center>
            <Flex direction="row" mt={{ base: '20px', lg: '0px' }}>
              <Text
                fontSize={{ base: '20px', lg: '25px' }}
                fontFamily="Avenir"
                lineHeight={{ base: '20px', lg: '35px' }}
                mr={3}
                ml={3}
              >
                to
              </Text>
              <VTextCarousel />
            </Flex>
          </Center>
        </Flex>
      ) : (
        <>
          <Button
            as={RouteLink}
            to="packageList"
            borderColor="white.12"
            borderRadius="28px"
            h="55px"
            mr="20px"
            params={{ packageType: RegistryPackageType.Provider }}
            append="?starred=true"
            lineHeight="20px"
            p="14px 18px"
            variant="blackOutline"
            _hover={{ background: '#F8F8F8', textDecoration: 'none' }}
          >
            <Wrap pt={1}>
              <StarOutlineIcon color="purple.6" width="20px" height="16px" name="starOutline" role="StarBadge" />
              <Text fontSize="16px" fontFamily="Avenir" fontWeight={500}>
                View My Stars
              </Text>
            </Wrap>
          </Button>
          <Button
            as={Link}
            href={UPBOUND_DOCS_PUBLISH_URL}
            isExternal={true}
            borderColor="white.12"
            borderRadius="28px"
            h="55px"
            params={{ packageType: RegistryPackageType.Provider }}
            append="?starred=true"
            lineHeight="20px"
            p="14px 18px"
            variant="blackOutline"
            _hover={{ background: '#F8F8F8', textDecoration: 'none' }}
          >
            <Wrap pt={1} spacing={3}>
              <PublishIcon
                overflow="visible"
                fill="purple.600"
                width="16px"
                h="16px"
                name="publishIcon"
                role="PublishPackage"
              />
              <Text fontSize="16px" fontFamily="Avenir" fontWeight={500}>
                Publish a Package
              </Text>
            </Wrap>
          </Button>
        </>
      )}
    </Flex>
  );
};
export default TitleButtons;
