import { Box, Flex, Heading, HStack, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import OfficialCheckmarkIcon from '@/assets/official-checkmark-icon.svg?react';
import PackageIcon from '@/components/PackageIcon';
import RouteLink, { RouteLinkProps } from '@/components/RouteLink';
import StarBadge from '@/components/StarBadge';
import { useCurrentUser } from '@/contexts/currentUser';
import {
  GetPackageUserSettingsDocument,
  RegistryPackageType,
  RegistrySearchResult,
  RepositoryTier,
  useGetPackageUserSettingsQuery,
  useUpdateUserSettingsMutation,
} from '@/graphql/upbound-graphql';
import { accountsLoginUrl } from '@/utils/constants/urls';
import { getIsFamilyProvider } from '@/utils/helpers/packages';
import { tryCatch } from '@/utils/helpers/routing';

const PackageDisplayCard: React.FC<RouteLinkProps & { data: RegistrySearchResult; isSmallScreen: boolean }> = ({
  data,
  isSmallScreen,
  ...props
}) => {
  const { currentUser } = useCurrentUser();
  const router = useRouter();
  const [updateUserSettings] = useUpdateUserSettingsMutation();

  const getStarsVariables = { packageAccount: data.account, packageName: data.name, packageVersion: 'latest' };
  // Rely on the apollo client cache and expect this is already fetched by fetchStars on the landing page index file.
  const { data: starData } = useGetPackageUserSettingsQuery({
    fetchPolicy: 'cache-only',
    variables: getStarsVariables,
  });

  const isFamilyProvider = getIsFamilyProvider(data);

  const onStarClick = () => {
    if (!currentUser) {
      router.push(accountsLoginUrl(router.asPath));
    } else {
      updateUserSettings({
        variables: {
          payload: {
            account: data.account,
            name: data.name,
            starred: !starData?.registry.package.userSettings?.starred,
          },
        },
        refetchQueries: [
          {
            query: GetPackageUserSettingsDocument,
            variables: getStarsVariables,
          },
        ],
      }).catch(error => tryCatch(error));
    }
  };

  return (
    <RouteLink
      to="packageDetails"
      role="PackageAnchor"
      params={{
        packageType: data.packageType,
        packageOwner: data.account,
        packageName: data.name,
        packageVersion: data.version,
      }}
      borderRadius="8px"
      cursor="pointer"
      display="flex"
      flexDirection="column"
      position="relative"
      minW="200px"
      maxW={{ base: '100%', md: '19.5%' }}
      mr="10px"
      mt="15px"
      flexGrow="1"
      textAlign="left"
      _hover={{
        backgroundColor: 'white.7',
      }}
      p="20px 15px 20px"
      backgroundColor="#F8F8F8"
      {...props}
    >
      <Box overflow="hidden">
        <Flex direction={{ base: 'row', lg: 'column' }} justifyContent="space-between">
          {!isSmallScreen && !!starData && (
            <StarBadge
              isSignedIn={!!currentUser}
              isStarred={starData?.registry.package.userSettings?.starred ?? false}
              isTooltipDisabled={false}
              backgroundColor="white.0"
              _hover={{
                border: '1px solid',
                borderColor: 'black.0',
              }}
              border="1px solid"
              borderColor="white.5"
              borderRadius="5px"
              position="absolute"
              right="20px"
              top="20px"
              onClick={e => {
                e.preventDefault();
                onStarClick();
              }}
            />
          )}
          <Flex
            flexDirection="column"
            position="relative"
            top="0px"
            transition="top 0.2s ease-in-out"
            role="PackageExpander"
          >
            {!isSmallScreen && (
              <Box pr="20px">
                <PackageIcon data={data} width="45px" height="45px" isFamily={isFamilyProvider} />
              </Box>
            )}
            <Heading
              as="h4"
              size="sm"
              display="block"
              fontSize="16px"
              lineHeight="25px"
              mt="10px"
              color="black.3"
              fontWeight={900}
              noOfLines={1}
            >
              <Flex>{data.displayName}</Flex>
            </Heading>
            {data.tier === RepositoryTier.Official && (
              <HStack>
                <OfficialCheckmarkIcon />
                <Text color="#8F86FF" fontWeight={400} fontFamily="Avenir" fontSize="14px" lineHeight="20px">
                  Upbound Official
                </Text>
              </HStack>
            )}
            {data.account && data.packageType === RegistryPackageType.Configuration && (
              <HStack>
                <Text color="#939EAB" fontWeight={400} fontFamily="Avenir" fontSize="14px" lineHeight="20px">
                  {data.account}
                </Text>
              </HStack>
            )}
          </Flex>
          {isSmallScreen && (
            <Box pr="20px">
              <PackageIcon data={data} width="45px" height="45px" />
            </Box>
          )}
        </Flex>
      </Box>
    </RouteLink>
  );
};

export default PackageDisplayCard;
