import { Flex, Heading, Text, Wrap } from '@chakra-ui/react';
import React from 'react';

import { RegistryPackageType } from '@/graphql/upbound-graphql';

import LinkText from './LinkText';
import PackageDisplayCard from './PackageDisplayCard';

const HorizontalPackageDisplay: React.FC<
  Partial<PageProps.PackageList> & {
    title: string;
    subtitle?: React.ReactNode;
    isSmallScreen: boolean;
    registryPackageType: RegistryPackageType;
    docsURL?: string;
  }
> = ({ data, subtitle, title, isSmallScreen, registryPackageType, docsURL }) => {
  return (
    <Flex width="100%" direction="column">
      <Heading width="100%" as="h2" size="md" fontSize={{ base: '20px', lg: '25px' }} whiteSpace="nowrap" mt={8} mb={2}>
        {title}
      </Heading>
      <Flex w="100%" mb={0} direction="row" justifyContent="space-between" alignItems="baseline">
        <Text width="100%" color="grey.2" fontSize="14px" mr={16}>
          {subtitle}
        </Text>
        {docsURL && <LinkText whiteSpace="nowrap" text="Docs" type="link" href={docsURL} mr={4} />}
        <LinkText
          whiteSpace="nowrap"
          text="See All"
          type="routeLink"
          params={{
            packageType: registryPackageType,
          }}
        />
      </Flex>

      <Wrap mt="20px" mb="40px" direction={{ base: 'column', lg: 'row' }} flexWrap="wrap">
        {data?.results.map((packageData, index) => (
          <PackageDisplayCard
            isSmallScreen={isSmallScreen}
            key={`${packageData.name}-${index}`}
            data={packageData}
            marginBottom={{ base: '20px', lg: '0' }}
            _last={{
              marginRight: '0',
              marginBottom: '0',
            }}
            _first={{
              marginLeft: '0',
            }}
          />
        ))}
      </Wrap>
    </Flex>
  );
};

export default HorizontalPackageDisplay;
