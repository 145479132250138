import { BoxProps, Flex, Heading, Link, Text } from '@chakra-ui/react';

import { UPBOUND_CONTACT_URL } from '@/utils/constants/urls';

const MessagingFaq: React.FC<BoxProps> = () => {
  return (
    <Flex width="100%" align="left" direction="column">
      <Heading
        as="h2"
        size="md"
        display="block"
        fontWeight={900}
        fontSize={{ base: '20px', lg: '25px' }}
        lineHeight={{ base: '37px', md: '48px' }}
        mb="20px"
      >
        Frequently Asked Questions
      </Heading>
      <Text fontSize="18px" lineHeight="32px" color="black.1" mb="40px">
        Still need more information? Read our FAQ or{' '}
        <Link
          href={UPBOUND_CONTACT_URL}
          fontSize="18px"
          lineHeight="32px"
          color="black.1"
          textDecoration="underline"
          _hover={{ textDecoration: 'none' }}
          isExternal={true}
        >
          contact us
        </Link>{' '}
        to learn more about the Upbound Marketplace.
      </Text>
    </Flex>
  );
};

export default MessagingFaq;
