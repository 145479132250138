import { Icon, IconProps } from '@chakra-ui/react';

const MinusIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <path
        fill="currentColor"
        stroke="none"
        d={`M2.5 6.75h11c0 0 1.25 0 1.25 1.25 0 0 0 1.25-1.25 1.25h-11c0 0-1.25 0-1.25-1.25 0 0 0-1.25 1.25-1.25z`}
      />
    </Icon>
  );
};

export default MinusIcon;
