import { Box, Flex, keyframes } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const VTextCarousel: React.FC = () => {
  const firstPhrase = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-20%);
    }
    15% {
        opacity: 1;
        transform: translateY(0);
    }
    35%, 100% {
        opacity: 0;
        transform: translateY(20%);
    }

`;

  const secondPhrase = keyframes`
  0%, 30% {
      opacity: 0;
      transform: translateY(-20%);
  }
  50% {
      opacity: 1;
      transform: translateY(0);
  }
  70%, 100% {
      opacity: 0;
      transform: translateY(20%);
    }
  `;

  const thirdPhrase = keyframes`
  0%, 65% {
      opacity: 0;
      transform: translateY(-20%);
  }
  80% {
      opacity: 1;
      transform: translateY(0);
  }
  100% {
      opacity: 0;
      transform: translateY(20%);
    }
  
  `;

  const firstPhraseAnimation = `${firstPhrase} 10s linear infinite`;
  const secondPhraseAnimation = `${secondPhrase} 10s linear infinite`;
  const thirdPhraseAnimation = `${thirdPhrase} 10s linear infinite`;

  return (
    <Flex>
      <Box
        as={motion.div}
        animation={firstPhraseAnimation}
        position="absolute"
        fontSize={{ base: '20px', lg: '25px' }}
        fontFamily="Avenir"
        lineHeight={{ base: '20px', lg: '35px' }}
        whiteSpace="nowrap"
      >
        Star Repositories
      </Box>
      <Box
        as={motion.div}
        animation={secondPhraseAnimation}
        fontSize={{ base: '20px', lg: '25px' }}
        fontFamily="Avenir"
        lineHeight={{ base: '20px', lg: '35px' }}
        whiteSpace="nowrap"
      >
        Publish Packages
      </Box>
      <Box
        as={motion.div}
        position="absolute"
        animation={thirdPhraseAnimation}
        fontSize={{ base: '20px', lg: '25px' }}
        fontFamily="Avenir"
        lineHeight={{ base: '20px', lg: '35px' }}
        whiteSpace="nowrap"
      >
        Manage Listings
      </Box>
    </Flex>
  );
};

export default VTextCarousel;
