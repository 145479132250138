import { Icon, IconProps } from '@chakra-ui/react';

const StarOutlineIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d={`M7.99782373,0 C8.37051109,0 8.70986869,0.205055169 8.87045756,0.52728472 L10.8187338,4.40282742 L15.172813,5.02385164 
        C15.5364104,5.07658011 15.8363783,5.32264632 15.9515175,5.65952267 C16.0666567,5.99639902 15.9727273,6.36842768 
        15.7121492,6.61742324 L12.5549115,9.63759295 L13.3033162,13.9056698 C13.3639158,14.257193 13.2124169,14.6116455 12.912449,14.8225594 
        C12.6124811,15.0334733 12.2155539,15.0569081 11.8883162,14.8899346 L8.00085371,12.8833233 L4.11339119,14.8899346 C3.78615351,15.0569081 
        3.38922631,15.0305439 3.08925843,14.8225594 C2.78929055,14.6145748 2.63779163,14.257193 2.6983912,13.9056698 L3.44376593,9.63759295 
        L0.286528251,6.61742324 C0.0259500934,6.36842768 -0.0649492639,5.99639902 0.0471599434,5.65952267 C0.159269151,5.32264632 0.462267008,5.07658011 
        0.825864437,5.02385164 L5.17691367,4.40282742 L7.1251899,0.52728472 C7.28880874,0.205055169 7.62513636,0 7.99782373,0 Z M10.1600547,5.31900024 
        L7.997,1.017 L5.83562057,5.31894487 L1.084,5.997 L4.52067138,9.28388991 L3.7,13.977 L8.00085371,11.7579626 L12.3,13.978 L11.4776862,9.28419585 
        L14.914,5.996 L10.1600547,5.31900024 Z`}
      />
    </Icon>
  );
};

export default StarOutlineIcon;
