import { IconProps, Image, ImageProps } from '@chakra-ui/react';

import { RegistryPackage } from '@/graphql/upbound-graphql';
import FamilyIcon from '@/icons/FamilyIcon';
import PlaceholderIcon from '@/icons/PlaceholderIcon';

type PackageIconProps = {
  data: Pick<RegistryPackage, 'icon'>;
  isFamily?: boolean;
  direction?: 'row' | 'column';
} & ImageProps &
  IconProps;

const PackageIcon: React.FC<PackageIconProps> = ({ data, isFamily, alt, direction, ...props }) => {
  if (!data.icon) {
    if (isFamily) {
      return <FamilyIcon isPlaceholder={true} direction={direction} {...props} />;
    }

    return <PlaceholderIcon {...props} name="placeholder" role="PackageIcon" />;
  }

  if (isFamily) {
    return <FamilyIcon {...props} direction={direction} src={data.icon} alt={alt} />;
  }

  return <Image {...props} src={data.icon} alt={alt} role="PackageIcon" />;
};

export default PackageIcon;
