import { ApolloClient, useApolloClient } from '@apollo/client';
import { useMediaQuery } from '@chakra-ui/media-query';
import { Center, Divider, Flex, Link } from '@chakra-ui/react';
import { NextPage } from 'next';
import { useEffect } from 'react';

import MarketplaceGraphicSVG from '@/assets/marketplace-graphic.svg?react';
import configurationAwsSvgUrl from '@/assets/packages/configuration-aws.svg';
import configurationAzureSvgUrl from '@/assets/packages/configuration-azure.svg';
import configurationCaaSSvg from '@/assets/packages/configuration-caas.svg';
import configurationDbaaSSvg from '@/assets/packages/configuration-dbaas.svg';
import configurationGcpSvgUrl from '@/assets/packages/configuration-gcp.svg';
import functionAutoReady from '@/assets/packages/function-auto-ready.svg';
import functionGoTemplating from '@/assets/packages/function-go-templating.svg';
import functionPatchTransform from '@/assets/packages/function-patch-and-transform.svg';
import providerAwsSvgUrl from '@/assets/packages/provider-aws.svg';
import providerAzureSvgUrl from '@/assets/packages/provider-azure.svg';
import providerAzureAdSvg from '@/assets/packages/provider-azuread.svg';
import providerGcpSvgUrl from '@/assets/packages/provider-gcp.svg';
import providerTerraformSvg from '@/assets/packages/provider-terraform.svg';
import PageTemplate from '@/components/PageTemplate';
import { useCurrentUser } from '@/contexts/currentUser';
import {
  GetPackageUserSettingsDocument,
  GetPackageUserSettingsQuery,
  GetPackageUserSettingsQueryVariables,
  RegistryPackageType,
  RegistrySearchResult,
  RepositoryTier,
} from '@/graphql/upbound-graphql';
import { UPBOUND_DOCS_PROVIDERS_URL, UPBOUND_DOCS_PUBLISH_URL } from '@/utils/constants/urls';
import { tryCatch } from '@/utils/helpers/routing';

import FaqDisplay from './__components__/FaqDisplay';
import HorizontalPackageDisplay from './__components__/HorizontalPackageDisplay';
import TitleButtons from './__components__/TitleButtons';
import TitleHeader from './__components__/TitleHeader';

const configurationResults = [
  {
    __typename: 'RegistrySearchResult',
    account: 'upbound',
    description:
      `This reference platform Configuration for Kubernetes and Data Services on Amazon Web Services ` +
      `(AWS) is a starting point to build, run, and operate your own internal cloud platform and offer a ` +
      `self-service console and API to your internal teams.`,
    displayName: 'platform-ref-aws',
    icon: configurationAwsSvgUrl,
    id: 'upbound/platform-ref-aws',
    name: 'platform-ref-aws',
    packageType: RegistryPackageType.Configuration,
    public: true,
    version: 'latest',
  } as RegistrySearchResult,
  {
    __typename: 'RegistrySearchResult',
    account: 'upbound',
    description:
      `This reference platform Configuration for Kubernetes and Data Services on Microsoft Azure is a starting ` +
      `point to build, run, and operate your own internal cloud platform and offer a self-service console and ` +
      `API to your internal teams.`,
    displayName: 'platform-ref-azure',
    icon: configurationAzureSvgUrl,
    id: 'upbound/platform-ref-azure',
    name: 'platform-ref-azure',
    packageType: RegistryPackageType.Configuration,
    public: true,
    version: 'latest',
  } as RegistrySearchResult,
  {
    __typename: 'RegistrySearchResult',
    account: 'upbound',
    description:
      `This reference platform Configuration for Kubernetes and Data Services on Google Cloud Platform (GCP) ` +
      `is a starting point to build, run, and operate your own internal cloud platform and offer a self-service` +
      ` console and API to your internal teams.`,
    displayName: 'platform-ref-gcp',
    icon: configurationGcpSvgUrl,
    id: 'upbound/platform-ref-gcp',
    name: 'platform-ref-gcp',
    packageType: RegistryPackageType.Configuration,
    public: true,
    version: 'latest',
  } as RegistrySearchResult,
  {
    __typename: 'RegistrySearchResult',
    account: 'upbound',
    description: `This configuration-caas configuration offers Kubernetes Clusters.`,
    displayName: 'configuration-caas',
    icon: configurationCaaSSvg,
    id: 'upbound/configuration-caas',
    name: 'configuration-caas',
    packageType: RegistryPackageType.Configuration,
    public: true,
    version: 'latest',
  } as RegistrySearchResult,
  {
    __typename: 'RegistrySearchResult',
    account: 'upbound',
    description: `This database-as-a-service configuration offers managed database abstractions.`,
    displayName: 'configuration-dbaas',
    icon: configurationDbaaSSvg,
    id: 'upbound/configuration-dbaas',
    name: 'configuration-dbaas',
    packageType: RegistryPackageType.Configuration,
    public: true,
    version: 'latest',
  } as RegistrySearchResult,
];

const providerResults = [
  {
    __typename: 'RegistrySearchResult',
    account: 'upbound',
    description: "Upbound's official Crossplane provider to manage Amazon Web Services (AWS) resources in Kubernetes.",
    displayName: 'provider-family-aws',
    icon: providerAwsSvgUrl,
    id: 'upbound/provider-family-aws/latest',
    name: 'provider-family-aws',
    tier: RepositoryTier.Official,
    packageType: RegistryPackageType.Provider,
    public: true,
    version: 'latest',
    repositoryId: 'upbound/provider-family-aws',
    familyRepositoryId: 'upbound/provider-family-aws',
  } as RegistrySearchResult,
  {
    __typename: 'RegistrySearchResult',
    account: 'upbound',
    description: "Upbound's official Crossplane provider to manage Microsoft Azure services in Kubernetes.",
    displayName: 'provider-family-azure',
    icon: providerAzureSvgUrl,
    id: 'upbound/provider-family-azure/latest',
    name: 'provider-family-azure',
    tier: RepositoryTier.Official,
    packageType: RegistryPackageType.Provider,
    public: true,
    version: 'latest',
    repositoryId: 'upbound/provider-family-azure',
    familyRepositoryId: 'upbound/provider-family-azure',
  } as RegistrySearchResult,
  {
    __typename: 'RegistrySearchResult',
    account: 'upbound',
    description: "Upbound's official Crossplane provider to manage Google Cloud Platform (GCP) services in Kubernetes.",
    displayName: 'provider-family-gcp',
    icon: providerGcpSvgUrl,
    id: 'upbound/provider-family-gcp/latest',
    name: 'provider-family-gcp',
    tier: RepositoryTier.Official,
    packageType: RegistryPackageType.Provider,
    public: true,
    version: 'latest',
    repositoryId: 'upbound/provider-family-gcp',
    familyRepositoryId: 'upbound/provider-family-gcp',
  } as RegistrySearchResult,

  {
    __typename: 'RegistrySearchResult',
    account: 'upbound',
    description: "Upbound's official Crossplane provider to manage Terraform services in Kubernetes.",
    displayName: 'provider-terraform',
    icon: providerTerraformSvg,
    id: 'upbound/provider-terraform',
    name: 'provider-terraform',
    tier: RepositoryTier.Official,
    packageType: RegistryPackageType.Provider,
    public: true,
    version: 'latest',
  } as RegistrySearchResult,
  {
    __typename: 'RegistrySearchResult',
    account: 'upbound',
    description: "Upbound's official Crossplane provider to manage Azure AD services in Kubernetes.",
    displayName: 'provider-azuread',
    icon: providerAzureAdSvg,
    id: 'upbound/provider-azuread',
    name: 'provider-azuread',
    tier: RepositoryTier.Official,
    packageType: RegistryPackageType.Provider,
    public: true,
    version: 'latest',
  } as RegistrySearchResult,
];

const functionResults = [
  {
    __typename: 'RegistrySearchResult',
    account: 'crossplane-contrib',
    description: 'Use patch & transform to configure composition',
    displayName: 'function-patch-and-transform',
    icon: functionPatchTransform,
    id: 'crossplane-contrib/function-patch-and-transform/latest',
    name: 'function-patch-and-transform',
    tier: RepositoryTier.Official,
    packageType: RegistryPackageType.Function,
    public: true,
    version: 'latest',
  } as RegistrySearchResult,
  {
    __typename: 'RegistrySearchResult',
    account: 'crossplane-contrib',
    description: 'Automatically detect when composed resources are ready',
    displayName: 'function-auto-ready',
    icon: functionAutoReady,
    id: 'crossplane-contrib/function-auto-ready/latest',
    name: 'function-auto-ready',
    tier: RepositoryTier.Official,
    packageType: RegistryPackageType.Function,
    public: true,
    version: 'latest',
  } as RegistrySearchResult,
  {
    __typename: 'RegistrySearchResult',
    account: 'crossplane-contrib',
    description: 'Use Go templating to configure composition',
    displayName: 'function-go-templating',
    icon: functionGoTemplating,
    id: 'crossplane-contrib/function-go-templating/latest',
    name: 'function-go-templating',
    tier: RepositoryTier.Official,
    packageType: RegistryPackageType.Function,
    public: true,
    version: 'latest',
  } as RegistrySearchResult,
];

const staticFeaturedPackages = {
  configurations: {
    count: 3,
    page: 1,
    size: 3,
    results: configurationResults,
  },
  providers: {
    count: 3,
    page: 1,
    size: 3,
    results: providerResults,
  },
  functions: {
    count: 3,
    page: 1,
    size: 3,
    results: functionResults,
  },
};

//Gray divider for landing page
const HorizontalDivider: React.FC = () => (
  <Center width="100%">
    <Divider maxWidth="1440px" mt="50px" mb="50px" w="100%" height="1px" backgroundColor="white.3" />
  </Center>
);

//subtitle component for Official Providers banner with embedded link
const ProvidersSubtitleLink: React.FC = () => (
  <>
    Providers bundle Managed Resources and controllers so Crossplane can manage infrastructure resources. Families
    contain shared types for related Providers. Official packages are fully maintained and covered by{' '}
    <Link
      _hover={{ textDecoration: 'none' }}
      color="#939EAB"
      fontSize="14px"
      href={UPBOUND_DOCS_PROVIDERS_URL}
      lineHeight="28px"
      textDecoration="underline"
    >
      Upbound Support
    </Link>
    .
  </>
);

/**
 * fetchStars fetches stars for all static featured packages all at once.
 *
 * Note: It relies on apollo client cache to not fetch again on page transition
 * as well as communicate the values to consumers which are 'cache-only'
 * fetches. It also relies on apollo link batching since it makes a heap of
 * requests but expects them to only result in a request or two in actuality.
 * @returns A tuple of package data and if stars have been fetched.
 */
const fetchStars = (client: ApolloClient<object>) => {
  const getStarredStatus = async (packageAccount: string, packageName: string) => {
    try {
      const { error } = await client.query<GetPackageUserSettingsQuery, GetPackageUserSettingsQueryVariables>({
        query: GetPackageUserSettingsDocument,
        variables: { packageAccount, packageName, packageVersion: 'latest' },
        fetchPolicy: 'cache-first',
      });
      if (error) {
        console.log('errored for packageName', packageName);
      }
    } catch (error) {
      tryCatch(error);
    }
  };

  [...configurationResults, ...providerResults, ...functionResults].forEach(r => getStarredStatus(r.account, r.name));
};

const RootIndexPage: NextPage = () => {
  const { currentUser } = useCurrentUser();
  const [isSmallScreen] = useMediaQuery('(max-width: 500px)');
  const client = useApolloClient();

  // We only want to fetch stars once.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchStars(client), []);

  return (
    <PageTemplate isSearchVisible={true}>
      <Flex
        justify-content="center"
        direction="column"
        width="100%"
        pr={{ base: '30px', lg: '90px' }}
        pl={{ base: '30px', lg: '90px' }}
      >
        <Center>
          <Flex
            width="100%"
            direction={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            mt={{ base: '10px', md: '40px', xl: '50px' }}
            maxWidth="1350px"
          >
            <Flex direction="column" width="100%" maxWidth="1350px" justifyContent={{ sm: 'center', md: 'center' }}>
              <Flex direction="column">
                <TitleHeader isSmallScreen={isSmallScreen} />
                <TitleButtons isSignedIn={!!currentUser} />
              </Flex>
            </Flex>
            <Flex
              justifyContent={{ sm: 'center', md: 'center', lg: 'end' }}
              mt={{ base: 5, lg: 10 }}
              mb={{ base: 5, lg: 10 }}
              mr={{ base: 0, lg: 10 }}
            >
              <MarketplaceGraphicSVG
                width={isSmallScreen ? '250px' : '500px'}
                height={isSmallScreen ? '150px' : '300px'}
              />
            </Flex>
          </Flex>
        </Center>
        <HorizontalDivider />
        <Center>
          <Flex direction="column" width="100%" maxWidth="1350px">
            <HorizontalPackageDisplay
              isSmallScreen={isSmallScreen}
              data={staticFeaturedPackages.providers}
              registryPackageType={RegistryPackageType.Provider}
              subtitle={<ProvidersSubtitleLink />}
              title="Providers"
              docsURL={UPBOUND_DOCS_PROVIDERS_URL}
            />
            <HorizontalPackageDisplay
              isSmallScreen={isSmallScreen}
              data={staticFeaturedPackages.configurations}
              registryPackageType={RegistryPackageType.Configuration}
              subtitle="Configurations compose Managed Resources into higher-level APIs that can be consumed by users of your internal cloud platform."
              title="Configurations"
              docsURL={UPBOUND_DOCS_PUBLISH_URL}
            />
            {!!staticFeaturedPackages.functions.results.length && (
              <HorizontalPackageDisplay
                isSmallScreen={isSmallScreen}
                data={staticFeaturedPackages.functions}
                registryPackageType={RegistryPackageType.Function}
                subtitle="Composition Functions allow you to use advanced logic, programming languages, and tools to configure your Crossplane Compositions."
                title="Composition Functions"
              />
            )}
          </Flex>
        </Center>

        <Center>
          <Flex width="100%" maxWidth="1350px">
            <FaqDisplay />
          </Flex>
        </Center>
      </Flex>
    </PageTemplate>
  );
};

export async function getStaticProps() {
  return {
    props: {},
    // While the page shouldn't change it doesn't "hurt" to revalidate and it will catch cases were the deploy revalidate to fail
    revalidate: 5 * 60, // 5 min
  };
}

export default RootIndexPage;
