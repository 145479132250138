import { BoxProps, Flex, Tooltip } from '@chakra-ui/react';

import StarFilledIcon from '@/icons/StarFilledIcon';
import StarOutlineIcon from '@/icons/StarOutlineIcon';

const StarBadge: React.FC<{ isStarred: boolean; isSignedIn: boolean; isTooltipDisabled: boolean } & BoxProps> = ({
  isStarred,
  isSignedIn,
  isTooltipDisabled,
  ...props
}) => {
  let tooltip = 'You must be signed in to star a repository';
  if (isSignedIn) {
    tooltip = isStarred ? 'Starred' : 'Star';
  }
  return (
    <Tooltip label={tooltip} placement="bottom" isDisabled={isTooltipDisabled}>
      <Flex {...props}>
        <Flex width="25px" height="25px" alignItems="center" justifyContent="center">
          {isStarred ? (
            <StarFilledIcon color="yellow.0" width="16px" height="16px" name="starFilled" role="StarBadge" />
          ) : (
            <StarOutlineIcon color="grey.1" width="16px" height="16px" name="starOutline" role="StarBadge" />
          )}
        </Flex>
      </Flex>
    </Tooltip>
  );
};

export default StarBadge;
