import { Flex, Heading, Text } from '@chakra-ui/react';

import CrossplaneIconSVG from '@/assets/crossplane-icon.svg?react';

const TitleHeader: React.FC<{ isSmallScreen: boolean }> = ({ isSmallScreen }) => {
  return (
    <Flex direction="column" justifyContent={'center'} alignSelf={{ base: 'center', lg: 'baseline' }}>
      <Heading as="h1" size="xl" width="100%" mb={{ base: '20px', lg: '40px' }} mt={{ base: '20px', lg: 0 }}>
        <Flex direction="column" justifyContent={{ base: 'center', lg: 'start' }}>
          <Flex direction="row">
            <Text
              color="black.0"
              fontSize={{ base: '25px', sm: '50px', lg: '60px' }}
              lineHeight={{ base: '30px', sm: '60px', lg: '70px' }}
            >
              Crossplane
            </Text>
            <CrossplaneIconSVG width={isSmallScreen ? '25px' : '55px'} height={isSmallScreen ? '30px' : '70px'} />
            <Text
              color="black.0"
              fontSize={{ base: '25px', sm: '50px', lg: '60px' }}
              lineHeight={{ base: '30px', sm: '60px', lg: '70px' }}
            >
              Providers
            </Text>
          </Flex>
          <Text
            color="black.0"
            fontSize={{ base: '25px', sm: '50px', lg: '60px' }}
            pl={{ sm: 2, md: 0, lg: 0 }}
            lineHeight={{ base: '30px', sm: '60px', lg: '70px' }}
            whiteSpace="nowrap"
          >
            and Configurations for
          </Text>
          <Text
            color="black.0"
            fontSize={{ base: '25px', sm: '50px', lg: '60px' }}
            pl={{ base: '30px', lg: 0 }}
            lineHeight={{ base: '30px', sm: '60px', lg: '70px' }}
          >
            your control plane
          </Text>
        </Flex>
      </Heading>
    </Flex>
  );
};

export default TitleHeader;
