import { Icon, IconProps } from '@chakra-ui/react';

const StarFilledIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d={`M8.87045756,0.52728472 C8.70986869,0.205055169 8.37051109,0 7.99782373,0 
        C7.62513636,0 7.28880874,0.205055169 7.1251899,0.52728472 L5.17691367,4.40282742 
        L0.825864437,5.02385164 C0.462267008,5.07658011 0.159269151,5.32264632 
        0.0471599434,5.65952267 C-0.0649492639,5.99639902 0.0259500934,6.36842768 
        0.286528251,6.61742324 L3.44376593,9.63759295 L2.6983912,13.9056698 
        C2.63779163,14.257193 2.78929055,14.6145748 3.08925843,14.8225594 
        C3.38922631,15.0305439 3.78615351,15.0569081 4.11339119,14.8899346 
        L8.00085371,12.8833233 L11.8883162,14.8899346 C12.2155539,15.0569081 
        12.6124811,15.0334733 12.912449,14.8225594 C13.2124169,14.6116455 
        13.3639158,14.257193 13.3033162,13.9056698 L12.5549115,9.63759295 
        L15.7121492,6.61742324 C15.9727273,6.36842768 16.0666567,5.99639902 
        15.9515175,5.65952267 C15.8363783,5.32264632 15.5364104,5.07658011 
        15.172813,5.02385164 L10.8187338,4.40282742 L8.87045756,0.52728472 Z`}
      />
    </Icon>
  );
};

export default StarFilledIcon;
